import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
// import { Home } from "./Components/Home";
import { Four } from "./Components/404";
// import { Projects } from "./Components/Projects";
// import { Navigation } from "./Components/Navigation";
import { UserProvider } from "./user";
// import { Strategy } from "./Components/Strategy";
// import { Contact } from "./Components/Contact";
// import { Art } from "./Components/Art";
import { Error } from "./Components/Error";

function App() {
  const settings = { language: "en" };
  return (
    <UserProvider settings={settings}>
      <Router>
        {/* <Navigation /> */}
        {/* <div className="App"> */}
        <Routes>
          <Route path="/" element={<Four />} />
          <Route path="*" element={<Error />} />
          {/* <Route path="/projects" element={<Projects />} />
          <Route path="/strategy" element={<Strategy />} />
          <Route path="/art" element={<Art />} />
          <Route path="/contact" element={<Contact />} /> */}
          {/* <Route path="/slim/:shady" element={<Slim />} /> */}
        </Routes>
        {/* </div> */}
        {/* <div className="footer">Cool</div> */}
      </Router>
    </UserProvider>
  );
}

export default App;
