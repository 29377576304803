import React from "react";
import logo from "./../pics/music8_logo.jpg";

export const Four = () => {
  return (
    <div>
      <div className="construction">
        <div className="logoback">
          <img className="logo" src={logo} alt=""></img>
        </div>
        <div className="div1">
          <text>
            Music 8 © PA and Musical Instruments Importers and Distributors
          </text>
          <text>
            Music 8 © Importateurs et distributeurs d'instruments de musique et
            PA
          </text>
        </div>
        <text>Website under construction...</text>
        <text>Site web en construction...</text>
      </div>
      <div className="footer">
        {" "}
        <text className="textfooter">
          Music 8 © PA and Musical Instruments Importers and Distributors
        </text>
        <text className="textfooter">
          Music 8 © Importateurs et distributeurs d'instruments de musique et PA
        </text>
      </div>
    </div>
  );
};
